import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from "react-helmet";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Menu from './Menu';
import Reservation from './Reservation';
import { ApiService } from "../../Components/Services/apiservices";
import Modal from 'react-bootstrap/Modal';

let apiServices = new ApiService();
function Home() {
  const [show , setShow] = useState(false);
  const handleClose = () => setShow(false);
  function handleShow()  {setShow(true);}
  const didMountRef = useRef(true)
  const [pageData, setPageData] = useState({})
  const [pageContent, setPageContent] = useState('');
  const [headerImage , setHeaderImage] = useState("")
  const [settingData, setSettingData] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [defaultImage, setDefaultImage] = useState('')
  
  useEffect(() => {
    if (didMountRef.current) {
      // setTimeout(() => {
      //  handleShow()
      // }, 30000);
      const dataString = {
        "page_url": "home"
      }
      apiServices.pagePostRequest(dataString).then(res => {
        if (res.data.status === "success") {
          if(res.data.pageData != null){
          setPageData(res.data.pageData)
          setPageContent(res.data.pageData.page_content)
          setHeaderImage(res.data.page_header_url)

          }
        }
      })

      apiServices.settingsdataGetRequest().then(res => {
        if (res.data.status == "success") {
            setImageUrl(res.data.settings_url)
            setSettingData(res.data.sitesettings)
            setDefaultImage(res.data.default_image_baseurl)
        }
    })

    }
    didMountRef.current = false;
  })
  
  return (
    <>
      <Helmet>
        <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc != null ? pageData.page_meta_desc :""} />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Vatika Indian Restaurant" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Vatika Indian Restaurant" />
        {pageData.page_header_image != null ? <meta property="og:image" content= {headerImage + pageData.page_header_image}/>
                :<meta property="og:image" content= {'/assets/img/logo.png'}/>}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Vatika Indian Restaurant" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta name="twitter:description" content={pageData.page_meta_desc} />
             {pageData.page_header_image != null ? <meta property="twitter:image" content= {headerImage + pageData.page_header_image}/>
                :<meta property="twitter:image" content= {'/assets/img/logo.png'}/>}
        <meta name="twitter:url" content={window.location.href} />
        <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
      </Helmet>
      <Header /> 
      {pageContent != null ? <div dangerouslySetInnerHTML={{ __html: pageContent }}></div> : ""}
      
      <Modal show={show} onHide={handleClose} size="lg">
          <Modal.Body className="login-modal-content"  >
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}></button>                           
          </Modal.Body>
      </Modal>


      <Menu />
      <Reservation />
      <Footer />
     
    </>
  )
}

export default Home