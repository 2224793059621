import React from 'react'
import { Link } from 'react-router-dom'

function Menu() {
  return (
    <>
      <section className="rooms1 section-padding bg-blck-light" data-scroll-index="2">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">Our Menu</div>
            </div>
          </div>
          <div className="row">
          <div className="col-md-3 col-6">
              <div className="item">
                <div className="position-re o-hidden"><Link to="/assets/pdf/mainmenu.pdf" target='_blank'><img src="/assets/img/food/mainmenu.jpg" alt="Wine Image" /></Link> </div>
       
                <div className="con">
           
                  <h5><Link to="/assets/pdf/mainmenu.pdf" target='_blank'>DINE IN MENU</Link></h5>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end ">
                      <div className="permalink"><Link to="/assets/pdf/mainmenu.pdf" target='_blank'>Download<i className="ti-arrow-down"></i></Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="item">
                <div className="position-re o-hidden"><Link to="/assets/pdf/takeawaymenu.pdf" target='_blank'><img src="/assets/img/food/takeaway.jpg" alt="" /></Link> </div>
                <div className="con">

                  <h5><Link to="/assets/pdf/takeawaymenu.pdf" target='_blank'>TAKEAWAY</Link> </h5>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end ">
                      <div className="permalink"><Link to="/assets/pdf/takeawaymenu.pdf" target='_blank'>Download<i className="ti-arrow-down"></i></Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
            <div className="col-md-3 col-6">
              <div className="item">
                <div className="position-re o-hidden"><Link to="/assets/pdf/drinksmenu.pdf" target='_blank'><img src="/assets/img/food/drinks.jpg" alt="Cocktail Image" /></Link> </div>
                <div className="con">
     
                  <h5><Link to="/assets/pdf/drinksmenu.pdf" target='_blank'>DRINKS</Link></h5>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end ">
                      <div className="permalink"><Link to="/assets/pdf/drinksmenu.pdf" target='_blank'>Download<i className="ti-arrow-down"></i></Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="item">
                <div className="position-re o-hidden"><Link to="/assets/pdf/dessertmenu.pdf" target='_blank'><img src="/assets/img/food/desserts.jpg" alt="Dessert Image" /></Link> </div>
        
                <div className="con">
              
                  <h5><Link to="/assets/pdf/dessertmenu.pdf" target='_blank'>DESSERTS</Link></h5>
                  <div className="line"></div>
                  <div className="row facilities">

                    <div className="col col-md-12 text-end ">
                      <div className="permalink"><Link to="/assets/pdf/dessertmenu.pdf" target='_blank'>Download<i className="ti-arrow-down"></i></Link></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </div>
        </div>
      </section>
    </>
  )
}

export default Menu