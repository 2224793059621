import React, { useEffect, useState, useRef } from 'react';
import { ApiService } from '../Services/apiservices';
import Modal from 'react-bootstrap/Modal';

let apiServices = new ApiService();
function Footer() {
  const [settingData, setSettingData] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [whatsappUrl , setWhatsappUrl] = useState('')
  const [defaultImage, setDefaultImage] = useState('')
  const didMountRef = useRef(true);
  const [show , setShow] = useState(false);
const [footer1, setFooter1] = useState("");
const [footer2, setFooter2] = useState("");
const [footer3, setFooter3] = useState("");





  useEffect(() => {
    if (didMountRef.current) {
      getFooterData();
      apiServices.settingsdataGetRequest().then(res => {
        if (res.data.status == "success") {
          setImageUrl(res.data.settings_url)
          setSettingData(res.data.sitesettings)
          setWhatsappUrl("https://wa.me/44"+res.data.sitesettings.admin_whatsapp_no)
          setDefaultImage(res.data.default_image_baseurl)


        }
      })
    }
    didMountRef.current = false;
  }, [])

  const getFooterData = () => {
  apiServices.footerDataGetRequest().then(res => {
    console.log(res);
    if (res.data.status === "success") {
      setFooter1(res.data.footer.footer_desc1)
      setFooter2(res.data.footer.footer_desc2)
      setFooter3(res.data.footer.footer_desc3)




    }
  })

};


 
  return (
    <>
      <footer className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row"> 
              <div className="col-md-3 col-12">
              
                <div className="footer-column footer-about">
                <div className="footer-logo" style={{marginBottom : '10px'}}>
         
                     <a className="footerlogo" href="/"> 
                     <img 
          src={settingData.footer_logo != null ? imageUrl + settingData.footer_logo : "/assets/img/vatikalogo1.png"} alt={settingData.logo}
           
          />

                     </a>
                     </div>
      
                  <p className="footer-about-text"

                   dangerouslySetInnerHTML={{ __html: settingData.admin_share_message }}>
                  
                    </p>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='row'>
                <div className="col-md-4 col-12"
             dangerouslySetInnerHTML={{ __html: footer3 }}
                  >
          
          
              </div>
              <div className="col-md-8 col-12"
              >
                <h3 className="footer-title">Contact Us</h3>
                  <div className="footer-contact-info">
                    <a href="https://maps.app.goo.gl/wkUmH75xWbCBUG14A" className="footer-contact-phone" target="new"><span className="flaticon-marker"></span> {settingData.address}</a>
                    </div>
                    <div className="footer-contact-info">

<a href={"tel:"+ settingData.admin_support_mobile} className="footer-contact-phone" style={{marginRight:"16px"}}><span className="flaticon-call"></span>  {settingData.admin_support_mobile}</a>

<a href={"mailto:"+ settingData.admin_email} className="footer-contact-phone"><span className="flaticon-envelope"></span> {settingData.admin_email}</a>

    </div>
              
                
              </div>
                </div>
         
              </div>
             
              
              
              <div className="col-md-3 col-12">
                <div className="footer-column footer-contact">
                  
                 
 

                            <h3 className="footer-title">Follow us on</h3>
                  <div className="footer-about-social-list">

                  {
                      settingData.facebook_url != null ?
                        <a href={settingData.facebook_url} target="new"><i className="ti-facebook"></i></a>
                        : null}

                    {
                      settingData.instagram_url != null ?
                        <a href={settingData.instagram_url} target="new"><i className="ti-instagram" ></i></a>
                        : null} 

                        </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="footer-bottom-inner">
                  <p className="footer-bottom-copy-right">© Copyright 2023 by <a href="/">Vatika Indian Restaurant. All rights reserved.</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
     
    </>
  )
}
export default Footer
