import React, { useEffect,useRef, useState } from 'react'
import { Helmet } from "react-helmet";
import Footer from "../../Components/Footer";
import {useParams} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import moment from 'moment';
import PagesHeader from '../../Components/PagesHeader';
import { ApiService } from "../../Components/Services/apiservices";
let apiServices = new ApiService();
function Contact() {
    const didMountRef = useRef(true)
    const { slug } = useParams();
    const [pageData , setPageData] = useState({})
    const [headerImage , setHeaderImage] = useState("")
    const[partnerData , setPartnerData] = useState([])
    const [partnerImageUrl , setPartnerImageUrl] = useState("")
    const[defaultImage , setDefaultImage] = useState("")
    const [settingData, setSettingData] = useState('')
    const [imageUrl, setImageUrl] = useState('')
    const [defaltImage, setDefaltImage] = useState('')
    const [Email, setEmail] = useState('');
    const [Mobile, setMobile] = useState('');
    const [Name, setName] = useState('');
    const [Subject, setSubject] = useState('');
    const [Message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessmessage] = useState('')
	const [calenderDate, setCalenderDate] = useState("")
    useEffect(() => {
        if (didMountRef.current) { 
            var today = new Date()
			var dd = today.getDate();
			var mm = today.getMonth() + 1;
			var yyyy = today.getFullYear();
			var calender = dd + "/" + mm + "/" + yyyy;
			setCalenderDate(calender)
       const dataString = {
      "page_url" : "contact"
       }
       apiServices.pagePostRequest(dataString).then(res => {
        if(res.data.status === "success"){
            if(res.data.pageData != null){
          setPageData(res.data.pageData)
          setHeaderImage(res.data.page_header_url)
        }
       }
    })

    apiServices.newspartnerUrlGetRequest().then(res=>{
        if(res.data.status === "success"){
          setPartnerData(res.data.partnerData)        
          setPartnerImageUrl(res.data.partner_url)
          setDefaultImage(res.data.default_image_baseurl)
        }

      })

      apiServices.settingsdataGetRequest().then(res => {
        if (res.data.status == "success") {
            setImageUrl(res.data.settings_url)
            setSettingData(res.data.sitesettings)
            setDefaltImage(res.data.default_image_baseurl)
        }
    })


    }

  didMountRef.current = false;
  }); 


  const submitmessage = (e) => {
    e.preventDefault()
    if (Name == "") {
        document.getElementById("contact-name").style.border = "1px solid red"
        return false
    }
    else if (Email == "") {
        document.getElementById("contact-email").style.border = "1px solid red"
        return false
    }
    else if (Mobile == "") {
        document.getElementById("contact-phone").style.border = "1px solid red"
        return false
    }
    else if (Subject == "") {
        document.getElementById("contact-subject").style.border = "1px solid red"
        return false
    }
    else if (Message == "") {
        document.getElementById("contact-message").style.border = "1px solid red"
        return false
    }
    else {

        setName("")
        setEmail("")
        setMobile("")
        setSubject("")
        setMessage("")
        document.getElementById("contact-name").style.border = "1px solid var()"
        document.getElementById("contact-email").style.border = "1px solid var()"
        document.getElementById("contact-phone").style.border = "1px solid var()"
        document.getElementById("contact-subject").style.border = "1px solid var()"
        document.getElementById("contact-message").style.border = "1px solid var()"


        const dataString = {
            "contact_name": Name,
            "contact_email": Email,
            "contact_mobile": Mobile,
            "contact_subject": Subject,
            "contact_message": Message,
        }
        apiServices.contactusprocessPostRequest(dataString).then(res => {
            console.log(res)
            if (res.data.status === "success") {
                setSuccessmessage(res.data.message)
               
            }
            else if (res.data.status === "error") {
                setErrorMessage(res.data.message)
            }
            setTimeout(() => {
                setSuccessmessage("")
                
            }, 3000);
        });
    }
}

  return (
    <>
    <Helmet>
    <title>{pageData.page_meta_title}</title>
        <meta name="description" itemprop="description" content={pageData.page_meta_desc} />
        <meta name="keywords" content={pageData.page_meta_keyword} />
        <link rel="canonical" href={window.location.href} />
        <meta name="robots" content="index,follow" />
        <meta name="author" content="Vatika Indian Restaurant" />
        <meta property="og:title" content={pageData.page_meta_title} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Vatika Indian Restaurant" />
        {pageData.page_header_image != null ? <meta property="og:image" content= {headerImage + pageData.page_header_image}/>
                :<meta property="og:image" content= {'/assets/img/logo.png'}/>}
        <meta property="og:url" content={window.location.href} />
        <meta property="og:description" content={pageData.page_meta_desc} />
        <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=5,user-scalable=1" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Vatika Indian Restaurant" />
        <meta name="twitter:title" content={pageData.page_meta_title} />
        <meta name="twitter:description" content={pageData.page_meta_desc} />
        {pageData.page_header_image != null ? <meta property="twitter:image" content= {headerImage + pageData.page_header_image}/>
                :<meta property="twitter:image" content= {'/assets/img/logo.png'}/>}
        <meta name="twitter:url" content={window.location.href} />
        <meta name="facebook-domain-verification" content="kq9rrxtq4is9uufvhdji8tf6oo7b8t" />
      </Helmet>
        <PagesHeader/>
    <div id="cappa-main">

    <div className="banner-header section-padding bg-position-bottom valign bg-img bg-fixed" data-overlay-dark="3" style={{backgroundImage: `url(${headerImage + pageData.page_header_image})`,backgroundSize:'cover',backgroundPosition:'100%'}}>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center caption mt-90">
                    <h5></h5>
                 {pageData.page_name != null  ? <h1>{pageData.page_name}</h1>:""}
                </div>
            </div>
        </div>
    </div>
    <section className="contact section-padding">
        <div className="container">
            <div className="row mb-90">
                <div className="col-md-6 mb-60">
                    
                    <h3><b>Vatika Indian Restaurant</b></h3>
                    <div id="showbookingboxcontact" className='mcbooking mb-30'></div>
                    <p>Get in touch with us to reserve a table or for any inquiries regarding corporate events, social gatherings, and more.</p>
                    {/* <p>Hotel ut nisl quam nestibulum ac quam nec odio elementum sceisue the aucan ligula. Orci varius natoque penatibus et magnis dis parturient monte nascete ridiculus mus nellentesque habitant morbine.</p> */}
                    <div className="reservations mb-30">
                        <div className="icon"><span className="flaticon-call"></span></div>
                        <div className="text">
                            <p>Reservations</p><a href={"tel:"+ settingData.admin_support_mobile} className="footer-contact-phone">{settingData.admin_support_mobile}</a>
                        </div>
                    </div>
                    <div className="reservations mb-30">
                        <div className="icon"><span className="flaticon-envelope"></span></div>
                        <div className="text">
                            <p>Email Info</p><a href={"mailto:"+ settingData.admin_email}>{settingData.admin_email}</a>
                        </div>
                    </div>
                    <div className="reservations">
                        <div className="icon"><span className="flaticon-location-pin"></span></div>
                        <div className="text">
                            <p>Address</p><a href="https://maps.app.goo.gl/wkUmH75xWbCBUG14A" target="new">{settingData.address}
                            <br/></a>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 mb-30 offset-md-1">
                    <h3><b>Get in touch</b></h3>
                    {successMessage != "" ?
                                    <div className="alert alert-success" role="alert">
                                        {successMessage}
                                    </div> : ""}
                                {errorMessage != "" ?
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div> : ""}


                    <form method="post" className="contact__form" >

                        <div className="row">
                            <div className="col-md-6 form-group mb-3">
                                <input name="contact-name" id="contact-name"  type="text" placeholder="Your Name *" onChange={(e) => setName(e.target.value)} value={Name} required/>
                            </div>
                            <div className="col-md-6 form-group mb-3">
                                <input name="contact-email" id="contact-email"  type="email" placeholder="Your Email *" onChange={(e) => setEmail(e.target.value)} value={Email} required/>
                            </div>
                            <div className="col-md-6 form-group mb-3" >
                                <input name="contact-phone" id="contact-phone"  type="number" placeholder="Your Number *" onChange={(e) =>  setMobile(e.target.value)} value={Mobile} required style={{ color:"#666"}}/>
                            </div>
                            <div className="col-md-6 form-group mb-3">
                                <input name="contact-subject" id="contact-subject"  type="text" placeholder="Subject *" onChange={(e) => setSubject(e.target.value)} value={Subject} required/>
                            </div>
                            <div className="col-md-12 form-group mb-3">
                                <textarea name="contact-message" id="contact-message" cols="30" rows="4" placeholder="Message *" onChange={(e) => setMessage(e.target.value)} value={Message} required></textarea>
                            </div>
                           
                                        
                            <div className="col-md-12">
                                <button type="submit" className="butn-dark2" name="submit" onClick={submitmessage}><span>Send</span></button>
                            </div>
                        </div>


                    </form>
                </div>
            </div>
           
            <div className="row">
                <div className="col-md-12 map animate-box" data-animate-effect="fadeInUp" >
               
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3393.0963813380076!2d115.7408832!3d-31.740570099999992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2bcd5567b0648555%3A0x71465bc60a5fbe2f!2sVatika%20Indian%20Restaurant%20Currambine!5e0!3m2!1sen!2sin!4v1696084402033!5m2!1sen!2sin" width={"100%"} height={"600"} style={{border:"0"}} allowFullScreen="" loading={"lazy"}></iframe>
                </div>
                
            </div>
        </div>
    </section>

   </div>


 <Footer/>



    </>
  )
}

export default Contact