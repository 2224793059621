import {BrowserRouter as Router, Route, Routes, Navigate, Switch} from 'react-router-dom';
import './App.css';
import Contact from './Containers/Contact';
import Menu from './Containers/Menu';
import Pages from './Containers/Pages';
import Home from './Containers/Home';
import Gallery from './Containers/Gallery';
import GalleryDetailPage from './Containers/GalleryDetailPage';



function App() {
  return (
    <div className="App">
  <Router>
  <Routes>    
  <Route exact path='/' activeClassName="active" element={ <Home /> }/>
  <Route exact path='/contact' activeClassName="active" element={ <Contact /> }/>
  <Route exact path='/menu' activeClassName="active" element={ <Menu /> }/>
  <Route exact path='/menu/:slug' activeClassName="active" element={ <Menu /> }/>
  <Route exact path='/gallery' activeClassName="active" element={ <Gallery /> }/>
  <Route exact path='/gallery/:slug' activeClassName="active" element={ <GalleryDetailPage /> }/>

  <Route exact path='/:slug' activeClassName="active" element={<Pages/>} />

 
  
  
  </Routes>
  </Router>
    </div>
  );
}

export default App;
