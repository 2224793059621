import React, { useState, useRef, useEffect } from 'react'
import { ApiService } from "../../Components/Services/apiservices";


let apiServices = new ApiService();

function Reservation() {
	const [calenderDate, setCalenderDate] = useState("")
	const [showLogin, setShowLogin] = useState(false);
	const didMountRef = useRef(true)
	const [settingData, setSettingData] = useState('')
	const [imageUrl, setImageUrl] = useState('')
	const [defaultImage, setDefaultImage] = useState('')
	const [Email, setEmail] = useState('');
    const [Mobile, setMobile] = useState('');
    const [Name, setName] = useState('');
    const [Message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessmessage] = useState('')
	useEffect(() => {
		if (didMountRef.current) {
			var today = new Date()
			var dd = today.getDate();
			var mm = today.getMonth() + 1;
			var yyyy = today.getFullYear();
			var calender = dd + "/" + mm + "/" + yyyy;
			setCalenderDate(calender)
		

			apiServices.settingsdataGetRequest().then(res => {
				if (res.data.status == "success") {
					setImageUrl(res.data.settings_url)
					setSettingData(res.data.sitesettings)
					setDefaultImage(res.data.default_image_baseurl)
				}
			})
		}
		didMountRef.current = false;
	}, []);
	
	
	const submitmessage = (e) => {
		e.preventDefault()
		if (Name == "") {
			document.getElementById("contact-name").style.border = "1px solid red"
			return false
		}
		else if (Email == "") {
			document.getElementById("contact-email").style.border = "1px solid red"
			return false
		}
		else if (Mobile == "") {
			document.getElementById("contact-phone").style.border = "1px solid red"
			return false
		}
		else if (Message == "") {
			document.getElementById("contact-message").style.border = "1px solid red"
			return false
		}
		else {
	
			setName("")
			setEmail("")
			setMobile("")
			setMessage("")
			document.getElementById("contact-name").style.border = "1px solid var()"
			document.getElementById("contact-email").style.border = "1px solid var()"
			document.getElementById("contact-phone").style.border = "1px solid var()"
			document.getElementById("contact-message").style.border = "1px solid var()"
	
	
			const dataString = {
				"contact_name": Name,
				"contact_email": Email,
				"contact_mobile": Mobile,
				"contact_message": Message,
			}
			apiServices.contactusprocessPostRequest(dataString).then(res => {
				console.log(res)
				if (res.data.status === "success") {
					setSuccessmessage(res.data.message)
				   
				}
				else if (res.data.status === "error") {
					setErrorMessage(res.data.message)
				}
				setTimeout(() => {
					setSuccessmessage("")
					
				}, 3000);
			});
		}
	}

	return (
		<>
		
			<section className="testimonials">
				<div className="background bg-img bg-fixed section-padding pb-0" data-background="/assets/img/contactbanner.jpg" data-overlay-dark="2">
					<div className="container">
						<div className="row">
							<div className="col-md-5 mb-30 mt-30">
								{/* <p><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i><i className="star-rating"></i></p> */}
								<h1 style={{fontSize: '75px'}}>Contact Us</h1>
								
							
			
							</div>
							<div className="col-md-5 offset-md-2">
								<div className="booking-box">
									{/* <div className="head-box">
										
										<h4>Table Booking Form</h4>
									</div> */}
									{/* <div className="booking-inner clearfix" id='bookingopentable'>
										<span className="spiralloader" style={{marginLeft:"auto" , marginRight:"auto", display:"block"}}></span> */}
										 <div className="booking-inner clearfix" >					
										<form action="#" className="form1 clearfix">
											<div className="row">
												<div className="col-md-12 mb-3">
												<h2><b>Get in touch</b></h2>
													<div className="input1_wrapper form-group">
														<label></label>
														<input type="text" className="form-control"  name="contact-name" id="contact-name"   placeholder="Your Name *" onChange={(e) => setName(e.target.value)} value={Name} required/>
													
													</div>
												</div>
												<div className="col-md-12 mb-3">
													<div className="select1_wrapper form-group">
														<label></label>

														<input className="select2 select" name="contact-email" id="contact-email"  type="email" placeholder="Your Email *" onChange={(e) => setEmail(e.target.value)} value={Email} required/>
														
													</div>
												</div>
												<div className="col-md-12 mb-3">
													<div className="select1_wrapper form-group">
														<label></label>

														
														<input className="select2 select" name="contact-phone" id="contact-phone"  type="number" placeholder="Your Number *"  onChange={(e) =>  setMobile(e.target.value)} value={Mobile} required style={{ color:"#666"}} />
														
													</div>
												</div>
												<div className="col-md-12 mb-3">
													<div className="input1_wrapper form-group">
														<label></label>
														<textarea name="contact-message" id="contact-message" cols="30" rows="4" placeholder="Message *"  onChange={(e) => setMessage(e.target.value)} value={Message}  required></textarea>
									
													
													</div>
												</div>
												
												<div className="col-md-12 ">
													<button type="submit" className="btn-form1-submit mt-15" onClick={submitmessage} >Send</button>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			
		</>
	)
}

export default Reservation