import React, { useEffect, useState, useRef } from 'react';
import { ApiService } from '../Services/apiservices';
import {useLocation} from "react-router-dom"; 
import Modal from 'react-bootstrap/Modal';


let apiServices = new ApiService();
function Header(props) {
const location = useLocation();
    const [settingData, setSettingData] = useState('')
    const [sliderData, setSliderData] = useState([]);
    const [menuData, setMenuData] = useState([]);
    const [imageUrl, setImageUrl] = useState('')
    const [bannerUrl, setBannerUrl] = useState('')
    const [defaultImage, setDefaultImage] = useState('')
    const didMountRef = useRef(true);
    const [show , setShow] = useState(false)
    const [showLogin, setShowLogin] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        if (didMountRef.current) {
            getmenu()
            apiServices.settingsdataGetRequest().then(res => {
                if (res.data.status == "success") {
                    setImageUrl(res.data.settings_url)
                    setSettingData(res.data.sitesettings)
                    setDefaultImage(res.data.default_image_baseurl)
                }
            })
        }
    
        didMountRef.current = false;
    }, [])

    const getmenu = () =>{
    apiServices.menuListGetRequest().then(res => {
        if (res.data.status == "success") {
            setMenuData(res.data.data)
        }
    })
} 

  
   const getslider = () =>{

    apiServices.sliderbannerGetRequest().then(res => {
        if (res.data.status == "success") {
       
            setSliderData(res.data.sliderData)
            setBannerUrl(res.data.banner_url)

           
        }
    })


   } 


//    const renderMenuItems = (items) => {
//     return items.map((menuItem, index) => (
//         <li key={index} className="nav-item ">
//             <a className="nav-link dropdown-item" href={menuItem.menu_customlink || menuItem.pages.page_url} target='_blank'>
//                 {menuItem.menu_name}
//             </a>
//             {menuItem.children && menuItem.children.length > 0 && (
//                 <ul className="dropdown-menu">
//                     {renderMenuItems(menuItem.children)}
//                 </ul>
//             )}
//         </li>
//     ));
// }
const renderMenuItems = (items) => {
    return items.map((menuItem, index) => (
        <li key={index} className="nav-item ">
            <a className="nav-link dropdown-item" href={menuItem.menu_customlink || (menuItem.pages && menuItem.pages.page_url)} target='_blank'>
                {menuItem.menu_name}
            </a>
            {menuItem.children && menuItem.children.length > 0 && (
                <ul className="dropdown-menu">
                    {renderMenuItems(menuItem.children)}
                </ul>
            )}
        </li>
    ));
}
const gotocustomlink =(navlink)=>{
    const url = "vatikaindianrestaurant.com.au" + navlink
window.open(url)
}

    return (
        <>
            <div className="progress-wrap cursor-pointer" >
                <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
                    <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
                </svg>
            </div>
            <nav className="navbar navbar-expand-lg">
        <div className="container">
            <div className="logo-wrapper">
           
                <a className="logo" href="/">
                <img
                className='logo-img'
                  src={settingData.logo != null ? imageUrl + settingData.logo : "/assets/img/headerlogo.png"} alt={settingData.logo}
                />
                    
                    </a>
            </div>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"><i className="ti-menu"></i></span> </button>
            <div className="collapse navbar-collapse justify-content-center" id="navbar">
                <ul className="navbar-nav ">

               
{/* {menuData.map((menuItem, index) => (
    <li key={index} className="nav-item dropdown">
        {menuItem.menu_pageid > 0 ? (
            <a className="nav-link dropdown-toggle" href={ "https://vatikaindianrestaurant.com.au/" + menuItem.pages.page_url} >
                {menuItem.menu_name}
            </a>
        ) : (
            <a className="nav-link dropdown-toggle" href={menuItem.menu_customlink} >
                {menuItem.menu_name}
            </a>
        )}
        {menuItem.children && menuItem.children.length > 0 && (
            <ul className="dropdown-menu">
                {renderMenuItems(menuItem.children)}
            </ul>
        )}
    </li>
))} */}
{menuData.map((menuItem, index) => (
    <li key={index} className="nav-item dropdown">
        {menuItem.menu_pageid > 0 ? (
            <a className="nav-link dropdown-toggle" href={ "https://vatikaindianrestaurant.com.au/" + menuItem.pages.page_url} >
                {menuItem.menu_name}
            </a>
        ) : (
            <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href={menuItem.menu_customlink} >
                {menuItem.menu_name}
            </a>
        )}
        {menuItem.children && menuItem.children.length > 0 && (
            <ul className="dropdown-menu">
                {renderMenuItems(menuItem.children)}
            </ul>
        )}
    </li>
))}
                </ul>
                <div className="header-about-social-list">

{
    settingData.facebook_url != null ?
      <a href={settingData.facebook_url} target="new"><i className="ti-facebook"></i></a>
      : null}

  {
    settingData.instagram_url != null ?
      <a href={settingData.instagram_url} target="new"><i className="ti-instagram" ></i></a>
      : null} 

      </div>
            </div>
        
        </div>
    </nav>


    <header className="header slider-fade">
  
            <div className="text-center item bg-img" data-background="/assets/img/slider/banner.webp">
                <div className="v-middle caption">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-10 offset-md-1">
                                <span>
                                    <i className="star-rating"></i>
                                    <i className="star-rating"></i>
                                    <i className="star-rating"></i>
                                    <i className="star-rating"></i>
                                    <i className="star-rating"></i>
                                </span>
                                <h2>Welcome to Vatika Indian Restaurant</h2>
                                <h1>Traditional & Authentic North Indian Cuisine</h1>
                                <div className="butn-dark"><a href="/contact" ><span>Contact Us</span></a></div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
    
   
        <div className="reservation">
            <a href={"tel:"+ settingData.admin_support_mobile}>
                <div className="icon d-flex justify-content-center align-items-center">
                    <i className="flaticon-call"></i>
                </div>
                <div className="call"><span>{settingData.admin_support_mobile}</span> <br/>Reservation</div>
            </a>
        </div>
    </header>

   
              
               
        </>
    )
}
export default Header
